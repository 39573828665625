// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAV5RVMMPapKmdg8MuWSr6boMh3PbvA7E8',
    authDomain: 'rm-softwares-web.firebaseapp.com',
    projectId: 'rm-softwares-web',
    storageBucket: 'rm-softwares-web.appspot.com',
    messagingSenderId: '489804847137',
    appId: '1:489804847137:web:fc0d489d57eb423f5fab60',
    measurementId: 'G-667HJZZ2HQ'
  }
};
